
  export default {
    computed: {
      videoUrl() {
        return this.$i18n.locale === 'en'
         ? 'https://www.youtube.com/embed/BHxpHFjEYvE?autoplay=1&amp;mute=1&amp;loop=1&amp;cc_load_policy=1rel=0&amp;controls=1&amp;showinfo=0&amp;playlist=BHxpHFjEYvE'
         : 'https://www.youtube.com/embed/bpIrIU8Tit4?autoplay=1&mute=1&loop=1&cc_load_policy=1rel=0&controls=1&showinfo=0&playlist=bpIrIU8Tit4'
      }
    }
  }
