
import HomeBanner from '@/components/home/HomeBanner.vue';
import HomeVideo from '@/components/home/HomeVideo.vue';
import BaseTextSection from '@/components/base/BaseTextSection.vue';
import HomeCarousel from '@/components/home/HomeCarousel.vue'
import HomeIcons from '@/components/home/HomeIcons.vue';
import HomeIdContent from '@/components/home/HomeIdContent.vue';
import HomeIdSocial from '@/components/home/HomeIdSocial.vue';

export default {
  components: {
    HomeBanner,
    HomeVideo,
    BaseTextSection,
    HomeCarousel,
    HomeIcons,
    HomeIdContent,
    HomeIdSocial
  },
  data() {
    return {
      observer: null,
      positiveInteger: 100,
      negativeInteger: -100,
      scrollPosition: 0,
      timer: null,
      animation: null
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.prevScrollPosition = this.scrollPosition;
      this.scrollPosition = window.scrollY;
      this.changeNumber()
    },
    scrollDirection() {
      if (this.scrollPosition > this.prevScrollPosition) {
        return 'down';
      } else if (this.scrollPosition < this.prevScrollPosition) {
        return 'up';
      } else {
        return 'none';
      }
    },
    changeNumber() {
      const scrollDirection = this.scrollDirection();
      let scrollNumber = this.scrollPosition / 1000

      if (scrollDirection === 'down') {
        if (Math.round(this.negativeInteger) === 100) return

        this.positiveInteger -= scrollNumber
        this.negativeInteger += scrollNumber
      } else if (scrollDirection === 'up') {
        if (Math.ceil(this.negativeInteger) === -100) return

        this.positiveInteger += scrollNumber
        this.negativeInteger -= scrollNumber
      }
    }
  },
  nuxtI18n: {
    paths: {
      en: '/',
      es: '/inicio',
      id: '/home'
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical', href: `https://home.trebel.io/
          ${this.$i18n.locale && this.$i18n.locale === 'id' ? '/id/home' : this.$i18n.locale === 'es' ? '/es/inicio' : ''}`
        }
      ],
      title: this.$t('meta.main.title'),
      meta: [
        {
          name: 'keywords',
          content: `TREBEL, Free music app, Music download app, Offline music player,
                    Download songs for free , Accessible offline music , Playlists for free ,
                    Album downloads , Artist-based music app , Free offline music streaming ,
                    TREBEL Music app , Offline song downloads , Listen to music offline , Free music downloads ,
                    Download and play music offline , Play offline songs , Stream music without internet ,
                    Free offline playlists , Music app offline access , Offline music player app ,
                    Listen to songs offline,  Free offline albums, Download and listen to music offline,
                    No-cost offline music, Offline music streaming app,  Download albums for free,
                    Listen to artists offline,  Play music without WiFi, Offline music,
                    Free download songs, Music for offline,
                    aplicación de música gratis, Aplicación para descargar música, Reproductor de música sin conexión,
                    descargar canciones gratis, Música sin conexión accesible, Listas de reproducción gratis, Descargas de álbumes,
                    Aplicación de música basada en artistas, Transmisión de música sin conexión gratuita, Aplicación TREBEL Música,
                    Descargas de canciones sin conexión, Escuchar música sin conexión, Descargas de música gratis,
                     Descargar y reproducir música sin conexión, Reproducir canciones sin conexión, Transmitir música sin internet,
                     Listas de reproducción sin conexión gratuitas, Aplicación de música con acceso sin conexión,
                    Aplicación de reproductor de música sin conexión, Escuchar canciones sin conexión, Álbumes gratuitos sin conexión,
                    Descarga y escucha música sin conexión, Música sin conexión sin coste, Aplicación de transmisión de música sin conexión,
                    Descargar álbumes gratis, Escuche artistas sin conexión, Reproducir música sin WiFi,
                    Experiencia musical sin conexión, canciones descargables gratis, Música para escuchar sin conexión,
                    Aplikasi musik gratis Aplikasi pengunduhan musik, Pemutar musik offline, Unduh lagu secara gratis,
                    Musik offline yang dapat diakses, Daftar putar gratis, Unduhan album,
                    Aplikasi musik berbasis artis, Streaming musik offline gratis, Aplikasi Musik TREBEL,
                    Download lagu offline, Dengarkan musik secara offline, Unduhan musik gratis, Unduh dan putar musik secara offline,
                    Putar lagu offline, Streaming musik tanpa internet, Daftar putar offline gratis, Aplikasi musik dengan akses offline,
                    Aplikasi pemutar musik offline, Dengarkan lagu secara offline, Album offline gratis,
                    Unduh dan dengarkan musik secara offline, Musik offline tanpa biaya, Aplikasi streaming musik offline,
                    Unduh album secara gratis, Dengarkan artis secara offline, Putar musik tanpa WiFi, Pengalaman musik offline,
                    Lagu yang dapat diunduh gratis, Musik untuk didengarkan secara offline, download mp3 gratis, music on download,
                    download musical, musical download, best free music download sites, download lagu gratis, musik download,
                    download lagu terbaru, free download music, download music free, www music download, download lagu baru,
                    lagu mp3 gratis,
                    mp3, free music archive, archive music free,`
        },
        {hid: "title", name: 'title', content: `${this.$t('meta.main.title')}`},
        {hid: "description", name: "description", content: `${this.$t('meta.main.description')}`,},
        {property: 'og:type', content: 'website'},
        {property: 'og:title', content: `Free music downloader app for Android and iOS | TREBEL Music`},
        {property: 'og:description', content: `Listen to millions of songs for free with TREBEL music downloader app for Android and iOS. Download today for mobile mp3 and music to start listening offline!`},
        {property: 'og:image', content: 'https://images.projectcarmen.com/resource/ads/64e8a9ff927cbb6b7d0fdadd.jpeg'},
        {property: 'og:image:type', content: 'image/png'},
        {property: 'og:url', content: 'https://www.home.trebel.io'},
        {property: 'twitter:card', content: 'summary_large_image'},
        {property: 'twitter:url', content: `https://home.trebel.io/${this.$i18n.locale && this.$i18n.locale === 'id' ? '/id/home' : this.$i18n.locale === 'es' ? '/es/inicio' : ''}`},
        {property: 'twitter:title', content: `${this.$t('meta.main.title')}`},
        {property: 'twitter:description', content: `${this.$t('meta.main.description')}`},
        {property: 'twitter:image', content: 'https://images.projectcarmen.com/resource/ads/64e8a9ff927cbb6b7d0fdadd.jpeg'},
        {property: 'fb:app_id', content: '614494898692745'},
        {property: 'al:ios:app_store_id', content: '912390687'},
        {property: 'al:ios:url', content: 'https://www.home.trebel.io'},
        {property: 'al:ios:app_name', content: 'Trebel Music - Song Downloader'},
        {property: 'al:android:package', content: 'com.mmm.trebelmusic'},
        {property: 'al:android:url', content: `trebel:/${this.$route.fullPath}`},
        {property: 'al:android:app_name', content: 'TREBEL - Free Music Downloads'},
      ]
    }
  }
}
