
export default {
  data() {
    return {
      content: [
        {
          title: "TREBEL TAKE OVER",
          imgSrc: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e52b513500ebdc545eb5a6_Group%201%20(2).png",
          desc: 'Mulai dari tanya jawab, sampai challenge menarik bareng musisi favoritmu!',
          btnText: 'KLIK DI SINI!',
          btnLink: "https://www.studiopress.com/"
        },
        {
          title: "TREBEL BACKSTAGE",
          imgSrc: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e52b516d6ee25c454ff52f_Rectangle%20325.png",
          desc: 'Nanyain pertanyaan tentang lagu sampe kehidupan musisi favoritmu di balik panggung!',
          btnText: 'KLIK DI SINI!',
          btnLink: "https://www.instagram.com/trebelid/"
        },
        {
          title: "TREBEL PERFORMANCE",
          imgSrc: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e52b51b622da9f602df955_Rectangle%20325%20(1).png",
          desc: 'Dengerin penampilan musisi favoritmu, eksklusif bersama TREBEL!',
          btnText: 'KLIK DI SINI!',
          btnLink: "https://www.youtube.com/@trebelid/playlists"
        },
      ]
    }
  }
}
