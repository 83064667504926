
export default {
  data() {
    return {
      socialIcons: [
        { href: 'https://www.instagram.com/trebelid/', imgScr: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e5382ed4680985107fd426_Frame%2038.png" },
        { href: 'https://twitter.com/Trebel_ID', imgScr: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e5382e20d10c1bdb7d3dca_Frame%2039.png" },
        { href: 'https://www.youtube.com/@trebelid', imgScr: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e5382ee3a06998b7af8dc4_Frame%2040.png" },
        { href: 'https://www.tiktok.com/@trebelid', imgScr: "https://uploads-ssl.webflow.com/602fbb305fedd148ad98287e/63e5382e8bc5033f965742d0_Frame%2041.png" }
      ]
    }
  }
}
